import { PaymentsService } from './../../shared/services/payments.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FirebaseService } from './../../shared/services/firebase.service';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { Assistant, ServerSidePages } from 'src/app/shared/models/';
import { ToastrService } from 'ngx-toastr';
import { PaginationTableHeaders } from 'src/app/shared/models/generic';
import Swal from 'sweetalert2';
import { GET_ALL_PAYMENTS_RESP, PaymentsData, PaymentsTableData } from 'src/app/shared/models/payments';
import * as _ from 'lodash';
import * as moment from 'moment';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Router } from '@angular/router';
import { UiSwitchComponent } from 'ngx-toggle-switch';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss'],
})
export class PaymentsComponent implements OnInit {
  paymentTableHeaders: PaginationTableHeaders[] = [
    { title: 'no', value: 'number', sort: false, orderBy: '' },
    {
      title: 'patient_name',
      value: 'patient_name',
      sort: true,
      orderBy: '',
    },
    { title: 'order_summary', value: 'order_summary', sort: true, orderBy: '' },
    { title: 'mrn', value: 'med_rec_number', sort: false, orderBy: '' },
    {
      title: 'gross_amount',
      value: 'est_out_of_pocket',
      sort: true,
      orderBy: '',
    },
    {
      title: 'stripe_fee',
      value: 'stripe_fee',
      sort: false,
      orderBy: '',
    },
    {
      title: 'net_amount',
      value: 'net_amount',
      sort: false,
      orderBy: '',
    },
    {
      title: 'paid_at',
      value: 'paid_at',
      sort: true,
      orderBy: '',
    },
  ];
  paymentTableHeadersForPaidPayments: PaginationTableHeaders[] = [
    { title: 'no', value: 'number', sort: false, orderBy: '' },
    {
      title: 'patient_name',
      value: 'patient_name',
      sort: false,
      orderBy: '',
    },
    {
      title: 'order_summary',
      value: 'order_summary',
      sort: false,
      orderBy: '',
    },
    { title: 'mrn', value: 'med_rec_number', sort: false, orderBy: '' },
    {
      title: 'gross_amount',
      value: 'amount',
      sort: true,
      orderBy: '',
    },
    {
      title: 'stripe_fee',
      value: 'stripe_fee',
      sort: false,
      orderBy: '',
    },
    {
      title: 'net_amount',
      value: 'net_amount',
      sort: false,
      orderBy: '',
    },
    {
      title: 'paid_at',
      value: 'paid_at',
      sort: true,
      orderBy: '',
    },
  ];
  paymentsTableData: PaymentsTableData[];
  paidPaymentsTableData: any;
  @ViewChild('attachmentSwitch', { static: false })
  attachmentSwitch: UiSwitchComponent;
  @ViewChild('attachmentSwitchForPaidPayments', { static: false })
  attachmentSwitchForPaidPayments: UiSwitchComponent;
  @ViewChild('attachmentSwitchForAutoPayments', { static: false })
  attachmentSwitchForAutoPayments: UiSwitchComponent;
  assistantInfo: Assistant = null;
  pageSize: number = 5;
  pageSizePaidPayments: number = 25;
  currentPage = 1;
  currentPagePaidPayments = 1;
  searchKeyword: string = '';
  searchKeywordForPaidPayments: string = '';
  searchCriteria: string = 'patient_name';
  searchCriteriaForPaidPayments: string = 'patient_name';
  style: string = 'flex';
  tablePages: ServerSidePages;
  tablePagesOfPaidPayments: ServerSidePages;
  userRole: string;
  // startDate: string = '';
  // endDate: string = '';
  startDate: string | null = null;
  endDate: string | null = null;
  constructor(
    private firebaseService: FirebaseService,
    private router: Router,
    private authService: AuthService,
    private toastr: ToastrService,
    public paymentService: PaymentsService,
    private spinnerService: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.init();
  }

  async init(): Promise<void> {
    const loggedInUser = JSON.parse(localStorage.getItem('user'));
    this.userRole = await this.authService.getUserRole();

    switch (this.userRole) {
      case 'location_admin': {
        this.router.navigate([`/dashboard/users`]);
        break;
      }
      case 'location_user': {
        this.router.navigate([`/dashboard/orders`]);
        break;
      }
      case 'practice_benefits': {
        this.router.navigate([`/dashboard/orders`]);
        break;
      }
      case 'practice_scribe': {
        this.router.navigate([`/dashboard/video-dictation`]);
        break;
      }
    }

    this.assistantInfo = await this.firebaseService.getAssistant(loggedInUser.uid, loggedInUser.displayName);
    this.getAllPayments();
    this.getAllPaidPayments();
  }

  openDateFilter(dataType: 'paidPayments' | 'allPayments'): void {
    Swal.fire({
      title: 'Select Date Range',
      html: `
        <label>Start Date:</label>
        <input id="start-date" type="date" class="swal2-input">
        <label>End Date:</label>
        <input id="end-date" type="date" class="swal2-input">
      `,
      showCancelButton: true,
      confirmButtonText: 'OK',
      customClass: {
        confirmButton: 'custom-swal-btn',
        cancelButton: 'custom-swal-btn',
      },
      preConfirm: () => {
        const startDate = (document.getElementById('start-date') as HTMLInputElement).value;
        const endDate = (document.getElementById('end-date') as HTMLInputElement).value;
        if (!startDate || !endDate) {
          Swal.showValidationMessage('Both start and end dates are required');
          return null;
        }
        return { startDate, endDate };
      },
    }).then((result) => {
      if (result.isConfirmed && result.value) {
        this.startDate = result.value.startDate;
        this.endDate = result.value.endDate;

        // Call your functions with the selected dates
        if (dataType === 'paidPayments') {
          this.getAllPayments(1, this.pageSize, {}, this.startDate, this.endDate);
        } else if (dataType === 'allPayments') {
          this.getAllPaidPayments(1, this.pageSize, {}, this.startDate, this.endDate);
        }
      }
    });
  }

  getAllPaidPayments(
    page: number = 1,
    limit: number = this.pageSize,
    orderBy: any = {},
    startDate?: string,
    endDate?: string
  ): void {
    this.spinnerService.show();

    this.paymentService
      .getAllPaidPayments(
        this.assistantInfo.practiceId,
        page,
        limit,
        orderBy,
        this.searchKeywordForPaidPayments,
        this.searchCriteriaForPaidPayments,
        this.attachmentSwitchForPaidPayments ? (this.attachmentSwitchForPaidPayments.checked ? true : false) : null,
        this.attachmentSwitchForAutoPayments ? (this.attachmentSwitchForAutoPayments.checked ? true : false) : null,
        // this.attachmentSwitch ?(this.attachmentSwitch.checked ? true :false) : null
        startDate, // Pass the start date
        endDate
      )
      .then((resp: GET_ALL_PAYMENTS_RESP) => {
        this.spinnerService.hide();
        const totalRecords = resp.data.total;
        const limit = resp.data.limit;

        this.tablePagesOfPaidPayments = {
          currentPage: resp.data.currentPage,
          // totalPages: Math.ceil((resp.data.total + resp.data.limit - 1) / resp.data.limit),
          // lastPage: Math.ceil((resp.data.total + resp.data.limit - 1) / resp.data.limit),
          totalPages: Math.ceil(totalRecords / limit),
          lastPage: Math.ceil(totalRecords / limit),
          nextPage: resp.data.nextPage,
          prevPage: resp.data.previousPage,
          firstPage: 1,
          total: resp.data.total,
        };

        this.paidPaymentsTableData = resp.data.data.map((payment: any, index: number) => ({
          no: index + 1,
          id: payment.id,
          order_id: payment.order_id,
          patient_id: payment.patient.patient_id,
          patient_name: payment.patient.patient_name,
          order_summary: payment.summary,
          mrn: payment.patient.med_rec_number,
          gross_amount: payment.payment.gross_amount + ' $',
          stripe_fee: payment.payment.stripe_fee + ' $',
          net_amount: payment.payment.net_amount + ' $',
          paid_at:
            payment.payment.paid_at &&
            moment.tz(payment.payment.paid_at, 'America/Los_Angeles').format('MM-DD-YYYY h:mm A'),
        }));
      })
      .catch((err) => {
        this.toastr.error('Something went wrong. Please try again');
        console.log(err);
      })
      .finally(() => this.spinnerService.hide());
  }

  getAllPayments(
    page: number = 1,
    limit: number = this.pageSize,
    orderBy: any = {},
    startDate?: string,
    endDate?: string
  ): void {
    this.spinnerService.show();

    this.paymentService
      .getAllPayments(
        this.assistantInfo.practiceId,
        page,
        limit,
        orderBy,
        this.searchKeyword,
        this.searchCriteria,
        this.attachmentSwitch ? (this.attachmentSwitch.checked ? true : false) : null,
        startDate,
        endDate
      )
      .then((resp: GET_ALL_PAYMENTS_RESP) => {
        this.spinnerService.hide();
        const totalRecords = resp.data.total;
        const limit = resp.data.limit;

        this.tablePages = {
          currentPage: resp.data.currentPage,
          // totalPages: Math.trunc((resp.data.total + resp.data.limit - 1) / resp.data.limit),
          // lastPage: Math.trunc((resp.data.total + resp.data.limit - 1) / resp.data.limit),
          totalPages: Math.ceil(totalRecords / limit),
          lastPage: Math.ceil(totalRecords / limit),
          nextPage: resp.data.nextPage,
          prevPage: resp.data.previousPage,
          firstPage: 1,
          total: resp.data.total,
        };
        this.paymentsTableData = resp.data.data.map((payment: PaymentsData, index: number) => ({
          no: index + 1,
          benefit_id: payment.benefit_id,
          order_id: payment.order_id,
          patient_id: payment.patient.patient_id,
          patient_name: payment.patient.patient_name,
          order_summary: payment.order_summary,
          mrn: payment.patient.med_rec_number,
          gross_amount: payment.payment.gross_amount + ' $',
          stripe_fee: payment.payment.stripe_fee + ' $',
          net_amount: payment.payment.net_amount + ' $',
          paid_at:
            payment.payment.paid_at &&
            moment.tz(payment.payment.paid_at, 'America/Los_Angeles').format('MM-DD-YYYY h:mm A'),
        }));
      })
      .catch((err) => {
        this.toastr.error('Something went wrong. Please try again');
        console.log(err);
      })
      .finally(() => this.spinnerService.hide());
  }

  archivedFunction = (record: PaymentsData): void => {
    Swal.fire({
      title: 'Archive/Unarchive',
      text: 'Are you sure you want to proceed ?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonColor: '#d33',
      confirmButtonText: 'Proceed',
      confirmButtonColor: '#073786',
    }).then((result) => {
      if (result.isConfirmed) {
        this.paymentService
          .archivePaymentReport(record.benefit_id, this.attachmentSwitch.checked ? false : true)
          .then((res) => {
            this.toastr.success('Request has been processed successfully');
            this.onChangeSwitch('paymentReports', this.tablePages.currentPage);
          })
          .catch((err) => {
            console.error(err);
            this.toastr.error(err.message);
          });
      }
    });
  };

  archivedFunctionForPaidPayments = (record: any): void => {
    Swal.fire({
      title: 'Archive/Unarchive',
      text: 'Are you sure you want to proceed ?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonColor: '#d33',
      confirmButtonText: 'Proceed',
      confirmButtonColor: '#073786',
    }).then((result) => {
      if (result.isConfirmed) {
        this.paymentService
          .archivePaidPaymentReport(record.id, this.attachmentSwitchForPaidPayments.checked ? false : true)
          .then((res) => {
            this.toastr.success('Request has been processed successfully');
            this.onChangeSwitch('paidPayments', this.tablePagesOfPaidPayments.currentPage);
          })
          .catch((err) => {
            console.error(err);
            this.toastr.error(err.message);
          });
      }
    });
  };

  exportPayments(category: string) {
    this.spinnerService.show();
    const startDateParam = this.startDate;
    const endDateParam = this.endDate;

    this.paymentService
      .exportPaymentsToCsv(
        category,
        this.attachmentSwitchForPaidPayments ? (this.attachmentSwitchForPaidPayments.checked ? true : false) : null,
        this.attachmentSwitchForAutoPayments ? (this.attachmentSwitchForAutoPayments.checked ? true : false) : null,
        this.attachmentSwitch ? (this.attachmentSwitch.checked ? true : false) : null,
        startDateParam,
        endDateParam
      )
      .then((res) => {
        this.spinnerService.hide();
        const blob = new Blob([res], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        if (category === 'statements') a.download = `paymentStatements(${moment().format('MM-DD-YYYY')}).csv`;
        else a.download = `OrderPayments(${moment().format('MM-DD-YYYY')}).csv`;
        a.click();
      })
      .catch((err) => {
        this.spinnerService.hide();
        console.error(err);
      });
  }

  onChangeSwitch(tableType: string, page = 1) {
    let order;
    let startDateParam = this.startDate;
    let endDateParam = this.endDate;
    if (tableType === 'paidPayments') {
      this.paymentTableHeadersForPaidPayments.forEach((header) => {
        if (header.orderBy) {
          order = {
            column_name: header.value,
            sort: header.orderBy,
          };
        }
      });
    }
    if (tableType !== 'paidPayments') {
      this.paymentTableHeaders.forEach((header) => {
        if (header.orderBy) {
          order = {
            column_name: header.value,
            sort: header.orderBy,
          };
        }
      });
    }
    tableType === 'paidPayments'
      ? this.getAllPaidPayments(page, this.pageSize, order, startDateParam, endDateParam)
      : this.getAllPayments(page, this.pageSize, order, startDateParam, endDateParam);
  }

  lastPage = (): void => {
    let order;
    let startDateParam = this.startDate;
    let endDateParam = this.endDate;

    this.paymentTableHeaders.forEach((header) => {
      if (header.orderBy) {
        order = {
          column_name: header.value,
          sort: header.orderBy,
        };
      }
    });

    if (this.tablePages.nextPage)
      this.getAllPayments(this.tablePages.lastPage, this.pageSize, order, startDateParam, endDateParam);
  };

  lastPagePaidPayments = (): void => {
    let order;
    let startDateParam = this.startDate;
    let endDateParam = this.endDate;

    this.paymentTableHeadersForPaidPayments.forEach((header) => {
      if (header.orderBy) {
        order = {
          column_name: header.value,
          sort: header.orderBy,
        };
      }
    });

    if (this.tablePagesOfPaidPayments.nextPage)
      this.getAllPaidPayments(
        this.tablePagesOfPaidPayments.lastPage,
        this.pageSize,
        order,
        startDateParam,
        endDateParam
      );
  };

  nextPage = (): void => {
    let order;
    let startDateParam = this.startDate;
    let endDateParam = this.endDate;

    this.paymentTableHeaders.forEach((header) => {
      if (header.orderBy) {
        order = {
          column_name: header.value,
          sort: header.orderBy,
        };
      }
    });
    if (this.tablePages.nextPage)
      this.getAllPayments(this.tablePages.nextPage, this.pageSize, order, startDateParam, endDateParam);
  };

  nextPagePaidPayments = (): void => {
    let order;
    let startDateParam = this.startDate;
    let endDateParam = this.endDate;

    this.paymentTableHeadersForPaidPayments.forEach((header) => {
      if (header.orderBy) {
        order = {
          column_name: header.value,
          sort: header.orderBy,
        };
      }
    });

    console.log('===', this.tablePagesOfPaidPayments);

    if (this.tablePagesOfPaidPayments.nextPage)
      this.getAllPaidPayments(
        this.tablePagesOfPaidPayments.nextPage,
        this.pageSize,
        order,
        startDateParam,
        endDateParam
      );
  };

  prevPage = (): void => {
    let order;
    let startDateParam = this.startDate;
    let endDateParam = this.endDate;

    this.paymentTableHeaders.forEach((header) => {
      if (header.orderBy) {
        order = {
          column_name: header.value,
          sort: header.orderBy,
        };
      }
    });

    if (this.tablePages.prevPage)
      this.getAllPayments(this.tablePages.prevPage, this.pageSize, order, startDateParam, endDateParam);
  };

  prevPagePaidPayments = (): void => {
    let order;
    let startDateParam = this.startDate;
    let endDateParam = this.endDate;

    this.paymentTableHeadersForPaidPayments.forEach((header) => {
      if (header.orderBy) {
        order = {
          column_name: header.value,
          sort: header.orderBy,
        };
      }
    });

    if (this.tablePagesOfPaidPayments.prevPage)
      this.getAllPaidPayments(
        this.tablePagesOfPaidPayments.prevPage,
        this.pageSize,
        order,
        startDateParam,
        endDateParam
      );
  };

  firstPage = (): void => {
    let order;
    let startDateParam = this.startDate;
    let endDateParam = this.endDate;

    this.paymentTableHeaders.forEach((header) => {
      if (header.orderBy) {
        order = {
          column_name: header.value,
          sort: header.orderBy,
        };
      }
    });

    if (this.tablePages.prevPage)
      this.getAllPayments(this.tablePages.firstPage, this.pageSize, order, startDateParam, endDateParam);
  };

  firstPagePaidPayments = (): void => {
    let order;
    let startDateParam = this.startDate;
    let endDateParam = this.endDate;

    this.paymentTableHeadersForPaidPayments.forEach((header) => {
      if (header.orderBy) {
        order = {
          column_name: header.value,
          sort: header.orderBy,
        };
      }
    });

    if (this.tablePagesOfPaidPayments.prevPage)
      this.getAllPaidPayments(
        this.tablePagesOfPaidPayments.firstPage,
        this.pageSize,
        order,
        startDateParam,
        endDateParam
      );
  };

  customPage = (page: string): void => {
    const pageNumber = parseInt(page);
    if (pageNumber === this.tablePages.currentPage) {
      this.toastr.info('Page already selected.');
      return;
    } else if (typeof pageNumber === 'number' && pageNumber <= this.tablePages.totalPages && 0 < pageNumber) {
      let order;
      let startDateParam = this.startDate;
      let endDateParam = this.endDate;

      this.paymentTableHeaders.forEach((header) => {
        if (header.orderBy) {
          order = {
            column_name: header.value,
            sort: header.orderBy,
          };
        }
      });

      this.getAllPayments(pageNumber, this.pageSize, order, startDateParam, endDateParam);
    } else
      this.toastr.error(
        'Please enter a page number between the range of ' +
          this.tablePages.firstPage +
          ' to ' +
          this.tablePages.lastPage
      );
  };

  customPagePaidPayments = (page: string): void => {
    const pageNumber = parseInt(page);
    if (pageNumber === this.tablePagesOfPaidPayments.currentPage) {
      this.toastr.info('Page already selected.');
      return;
    } else if (
      typeof pageNumber === 'number' &&
      pageNumber <= this.tablePagesOfPaidPayments.totalPages &&
      pageNumber >= this.tablePagesOfPaidPayments.firstPage
    ) {
      let order;
      let startDateParam = this.startDate;
      let endDateParam = this.endDate;

      this.paymentTableHeadersForPaidPayments.forEach((header) => {
        if (header.orderBy) {
          order = {
            column_name: header.value,
            sort: header.orderBy,
          };
        }
      });

      this.getAllPaidPayments(pageNumber, this.pageSize, order, startDateParam, endDateParam);
    } else {
      this.toastr.error(
        'Please enter a page number between the range of ' +
          this.tablePagesOfPaidPayments.firstPage +
          ' to ' +
          this.tablePagesOfPaidPayments.lastPage
      );
    }
  };

  orderBy = (column_name: string): void => {
    const index = this.paymentTableHeaders.findIndex((header) => header.value === column_name);

    this.paymentTableHeaders.forEach((header) => {
      if (header.value != column_name) header.orderBy = '';
    });

    if (this.paymentTableHeaders[index].orderBy === 'DESC') this.paymentTableHeaders[index].orderBy = 'ASC';
    else this.paymentTableHeaders[index].orderBy = 'DESC';

    const order = {
      column_name,
      sort: this.paymentTableHeaders[index].orderBy,
    };

    this.getAllPayments(this.tablePages.currentPage, this.pageSize, order, this.startDate, this.endDate);
  };

  orderByPaidPayments = (column_name: string): void => {
    const index = this.paymentTableHeadersForPaidPayments.findIndex((header) => header.value === column_name);

    this.paymentTableHeadersForPaidPayments.forEach((header) => {
      if (header.value != column_name) header.orderBy = '';
    });

    if (this.paymentTableHeadersForPaidPayments[index].orderBy === 'DESC')
      this.paymentTableHeadersForPaidPayments[index].orderBy = 'ASC';
    else this.paymentTableHeadersForPaidPayments[index].orderBy = 'DESC';

    const order = {
      column_name,
      sort: this.paymentTableHeadersForPaidPayments[index].orderBy,
    };

    this.getAllPaidPayments(
      this.tablePagesOfPaidPayments.currentPage,
      this.pageSize,
      order,
      this.startDate,
      this.endDate
    );
  };

  searchRecords(event: KeyboardEvent, tableType: string, clearSearch?: boolean): void {
    if (clearSearch && event.code === 'Enter') {
      this.searchKeyword = '';
      this.searchKeywordForPaidPayments = '';
    }

    let order;
    let startDateParam = this.startDate;
    let endDateParam = this.endDate;

    if (tableType === 'paidPayments') {
      this.paymentTableHeadersForPaidPayments.forEach((header) => {
        if (header.orderBy) {
          order = {
            column_name: header.value,
            sort: header.orderBy,
          };
        }
      });
    }
    if (tableType !== 'paidPayments') {
      this.paymentTableHeaders.forEach((header) => {
        if (header.orderBy) {
          order = {
            column_name: header.value,
            sort: header.orderBy,
          };
        }
      });
    }

    tableType === 'paidPayments'
      ? this.getAllPaidPayments(1, this.pageSize, order, startDateParam, endDateParam)
      : this.getAllPayments(1, this.pageSize, order, startDateParam, endDateParam);
  }
}
